.blank {
	background: $body-bg url('/img/topo-background.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;

	.bg-overlay {
	    position: absolute;
	    top: 0;
	    left: 0;
	    height: 100vh;
	    width: 100%;
	    background: #252b33;
	    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
	    opacity: 0.3;
	}

	#main {
        padding-top: 0;
    }

    p {
    	font-size: 2rem;
    }
    
    .container-vert{
        height:100%;
        display:table;
        width: 100%;
        padding: 0;
    }

    .row-vert {
        height: 100%;
        display:table-cell;
        vertical-align: middle;
    }

    .full-image-credit {
        position: absolute;
        bottom: 20px;
        right: 20px;
        padding-top: 10px;
        font-size: 1.2rem;
        opacity: 0.5;
    }

    .image-credit-text {
        color: #FFF;
    }

    .image-credit-text:hover {
        color: #FFF;
    }

    h1 {
    	font-family: $font-family-sans-serif;
    	font-size: 50px;
        text-transform: uppercase;
        color: $body-bg;
        margin-bottom: 2rem;
    }
}
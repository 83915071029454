.grid {
	
	&.gallery {

		.grid-item-content {
		    background-color: $gray-light;
		    height: 350px;
		    overflow: hidden;
		    margin-bottom: 0;
		    box-shadow: 0 0 10px rgba(0,0,0,0.1);
		    border: 2px solid #FFF;
		    cursor: pointer;

		    img {
		    	min-width:350px;
		    	height:350px;
		    }
		}

	}

	&.map-grid {
		
		.grid-item-content {
		    overflow: hidden;
		    margin-bottom: 0;
		    border: 2px solid #FFF;
		    cursor: pointer;
		    border: 0;
		    box-shadow: none;

		    img {
		    	height: 210px;
		    }
		}

	}
}
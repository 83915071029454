.auth {
	background: $body-bg url('/img/topo-background.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;

    h1 {
    	font-family: $font-family-heading;
    	font-size: 48px;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    form {
    	color: $gray-light;

    	a {
	        color: $gray-light;
	        text-decoration: underline;
	    }

	    a:hover {
	        color: $gray-light;
	        text-decoration: none;
	    }

	    a.plain {
	    	text-decoration: none;
	    }

	    ::-webkit-input-placeholder { color: #FFF; }
		:-moz-placeholder { /* Firefox 18- */ color: #FFF;}
		::-moz-placeholder {  /* Firefox 19+ */color: #FFF; }
		:-ms-input-placeholder { color: #FFF; }

		.form-control {
		    height: 45px;
		    font-size: 16px;
		    background: rgba(255,255,255,0.4);
		    color: #FFF;
		    border: 0;
		}
	}

    
}
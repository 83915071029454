.navbar {
	color: $navbar-default-color;
	background-color: transparent;
    background: transparent;
	font-family: $headings-font-family;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: $navbar-margin-bottom;
	border-radius: 0;
	min-height: $navbar-height;
	z-index: $zindex-navbar;
	border: 0;

	&-nav,
	&-form {
		margin-left: 0;
		margin-right: 0;
	}

	&-nav > li > a {
		color: $navbar-default-color;
		$margin-vertical: (($navbar-height - 2*$padding-base-vertical - $line-height-computed - 2px) / 2);
		margin: $margin-vertical 6px;
		padding: $padding-base-vertical $padding-base-horizontal;
		border: none;
		text-decoration: none;

		&:hover {
			 border: none;
			 color: $navbar-default-color;
		}
	}

	&-default .navbar-nav > .active > a,
	&-default .navbar-nav > .active > a:hover,
	&-default .navbar-nav > .active > a:active,
	&-default .navbar-nav > .active > a:focus,
	&-default .navbar-nav > .active > a:visited,
	&-default .navbar-nav > .active > a:hover {
		background-color: transparent;
	}

	&-default .navbar-nav > .active > a,
	&-default .navbar-nav > .active > a:hover {
		border: none;
		color: $brand-primary;
	}

	&-inverse .navbar-nav > .active > a:hover {
		color: $navbar-inverse-link-hover-color;
	}

	&-brand {
		padding-top: (($navbar-height - 2*$font-size-large) / 2);
		padding-bottom: (($navbar-height - 2*$font-size-large) / 2);
		line-height: 1.9;
	}

	.navbar-collapse {
		border: 0;
		background: $body-bg;
		height: 700px;
	}

	.navbar-toggle {
		border: 0;
		margin-top: 14px;

		.icon-bar {
			background-color: $brand-secondary;
			width: 26px;
			height: 3px;
		}
	}

	.navbar-toggle:hover, .navbar-toggle:active, .navbar-toggle:focus {
		background-color: transparent;
	}

	.navbar-brand {
		font-size: 28px;

		i {
			color: $brand-primary;
		}
	}

	.navbar-nav {
		padding-top: 4px;

		> li > a {
			color: $navbar-default-color;
			font-size: 20px;
			margin-top: 2rem !important;
			text-align: center;
			text-transform: uppercase;
		}

		> li > a:hover {
			color: #777C90;
		}
	}

	.navbar-dropdown {
		color: $navbar-default-color;
    	text-decoration: none;
    	margin-top: 2.25rem;
		text-align: center;
    	font-size: 2rem;

    	a {
    		color: $navbar-default-color;
    		text-transform: uppercase;
    		text-decoration: none;
    	}
	}

	.dropdown-menu {
		color: $body-bg;
		background-color: $navbar-default-bg;
		top: 40px;
		text-align: center;
    	font-size: 2rem;

    	> li > a {
    		padding: 5px 15px 5px 0px;
    	}

		li {
			font-size: 16px;
			padding: 5px 15px 5px 0px;

			a {
				font-weight: 300;
				line-height: 25px;
				vertical-align: middle;
				color: $body-bg;
			}

			a:hover {
				background-color: $navbar-default-bg;
				color: $brand-primary;
			}
		}
	}

	.caret {
		color: $brand-primary;
	}

	.navbar-text {
		margin-left: 0;
		margin-right: 0;
	}
}

.navbar-primary {
	position: absolute;
	width:100%;
}

.navbar-subnav {
	color: $body-bg;
	background-color: $brand-secondary;

	.navbar-nav > li > a {
    	color: $body-bg;
	}

	.navbar-text {
		margin-top: 20px;
	}

	.fa {
		font-size: 2rem;
		margin-left: 1.5rem;
		color: $body-bg;
	}

	a, a:hover, a:active, a:focus {
		color: $body-bg;
	}
}

.nav {
	.open > a,
	.open > a:hover,
	.open > a:focus {
		border: none;
		background-color: transparent;
	}
}

/* nav pills */
.nav-pills {
	margin-top: -.7rem;
    margin-bottom: -0.6rem;

	li {
		a,a:hover, a:focus {
			font-size: 2.5rem;
			color: $brand-secondary;
			background-color: transparent;
			padding: 0px;
			margin-right: 2rem;
		}
	}

	li.active {
		a,a:hover, a:focus {
			font-size: 2.5rem;
			color: $brand-primary;
			background-color: transparent;
			border-bottom: 3px solid $brand-primary;
			border-radius: 0;
		}
	}
}

/* search filters */
.filter-row {
	margin-top: 4rem;

	a,a:hover, a:focus {
		text-decoration: none;
	}

	.filter-group {
		text-align: center;
	}

	.filter {
		display: inline-block;
		margin-right: 1.5rem;
		font-family: $font-family-serif;
		font-size: 1.5rem;
		color: $brand-secondary;
		cursor: pointer;

		&.is-checked {
			color: $brand-primary;
			border-bottom: 3px solid $brand-primary;
			border-radius: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.filter-title {
		font-size: 1.75rem;
	}
}


/* social links bar */
.social-links {
	cursor: pointer;

	a {
		color: $body-bg;
	}

	a:hover {
		color: #FFF;
	}
}

.pagination {
  	&-lg > li > a,
  	&-lg > li > span {
    	padding: 14px 24px;
  }
}

.pager {

	font-family: $headings-font-family;

	a {
		color: $text-color;
	}

	a:hover {
		border-color: transparent;
		color: #fff;
	}

	.disabled a {
		border-color: $pager-border;
	}
}

.breadcrumb {
	padding: 0;
    margin-bottom:0;
	background-color: transparent;

	a {
		color: $text-color;
	}
}

@media (min-width: $grid-float-breakpoint) {
  .navbar {
    .navbar-nav > li > a {
      padding: $padding-base-vertical $padding-base-horizontal;
    }
  }
}

@media (max-width: ($grid-float-breakpoint - 1)) {
  .navbar {
    .navbar-nav > li > a {
      margin: 0;
    }
  }
}


@media (min-width: $screen-md-min) {
	.navbar {
		&-default .navbar-nav > .active > a,
		&-default .navbar-nav > .active > a:hover {
			border-bottom: 3px solid $brand-primary;
		}

		.navbar-collapse {
			background: transparent;
		}
	}
}

@media (min-width: $screen-lg-min) {
	.navbar {
		.navbar-nav {
			padding-top: 4px;

			> li > a {
				font-size: 16px;
			}
		}

		.navbar-dropdown {
    		margin-top: 2.65rem;
    		font-size: 16px;
    	}

    	.dropdown-menu {
			text-align: left;
    		font-size: 2rem;

    		> li > a {
    			padding: 5px 15px 5px 25px;
    		}
    	}
	}

	.navbar-collapse {
		padding-right: 0;
		margin-left: -15px;
		margin-right: -15px;
	}

	.filter-row {
		margin-top: 8rem;

		.filter {
			margin-right: 4rem;
			font-size: 2rem;
		}

		.filter-last {
			margin-right: 0;
		}
	}
}
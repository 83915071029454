.close {
  color: #fff;
  text-decoration: none;
  text-shadow: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }
}

.alert {
    a,
    .alert-link {
        border-bottom-width: 2px;
        border-bottom-style: solid;
    }
}

.pointer {
    cursor: pointer;
}

.label {
  font-family: $headings-font-family;
  font-weight: normal;

  &-default {
    border: 1px solid #ddd;
    color: $text-color;
  }

}

.badge {
  padding: 1px 7px 5px;
  vertical-align: 2px;
  font-family: $headings-font-family;
  font-weight: normal;
}

.small {
    font-size: $font-size-small;
}

.faded {
    opacity: 0.75;

    a {
        color: $gray;
        font-weight: normal;
        border-bottom: none;
    }
}

.faded-light {
    opacity: 0.35;

    a {
        color: $gray-light;
        font-weight: normal;
        border-bottom: none;
    }
}

/* pagination */
.paginator-text {
    font-family: $font-family-sans-serif;
    margin-top: 2rem;
    font-size: 2rem;
}

.pagination {
    font-family: $font-family-sans-serif;

    li {
        border-radius: 0;
        background-color: transparent;

        a {
            color: $text-color;
            background-color: transparent;
            border-radius: 0;
            border: 0;
        }

        a:hover,a:focus {
            color: $brand-primary;
            background-color: transparent;
        }
    }

    li:first-child {
        a {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    .active {
        border-radius: 0;
        background-color: transparent;

        a {
            background-color: transparent;
            color: $brand-primary;
            border-bottom: 3px solid $brand-primary;
            border-radius: 0;
        }

        a:hover,a:focus {
            color: $brand-primary;
            background-color: transparent;
            border-bottom: 3px solid $brand-primary;
            border-radius: 0;
        }
    }
}
[class*="map"]:not(#map-upload) {
    .leaflet-control-container .leaflet-control-layers-expanded {
        background: rgba(255, 255, 247, 0.9);
        border-left: solid 1px #4B4E5D;
        border-right: solid 4px #4B4E5D;
        border-bottom: solid 2px #4B4E5D;
        padding-left: 35px;
        min-height: 200px;

        h6 {
            font-family: "LTCSquareFacePro", Georgia, "Times New Roman", Times, serif;
            color: #4B4E5D;
            font-size: 1.5rem;
            text-transform: uppercase;
        }
        .slider-column {
            border-right: solid thin #CCC;
            width: 28px;
            height: 96%;
            display: inline-block;
            position: absolute;
            left: -32px;
        }
        i {
            position: relative;
            left: 5px;
            color: #4B4E5D;
        }
    }
}
.leaflet-control-layers-overlays label {

}

/* hero */
.hero {
    color: $text-color;
    display: table;

    .hero-pretitle {
        font-size: 2.25rem;
        font-family: $font-family-heading;
        color: $brand-secondary;
    }

    a {
        color: $text-color;
        text-decoration: underline;
    }

    a:hover {
        color: $gray-light;
        text-decoration: none;
    }

    .hero-form, .hero-title {
        width:100%;
        margin: 0 auto;
        display: table-cell;
        vertical-align:middle;
    }

    .hero-subtitle {
        font-size: 2rem;
        color: $body-bg;
    }
}


.video-hero, .no-video-hero, .blog-hero {
    position: absolute;
    top: 0;
    color: $body-bg;
    background-color: rgba(0, 0, 0, 0.6);
    height: 575px;
    width: 100%;
    display:table;

    h1 {
        color: $body-bg;
    }

    a {
        color: $body-bg;
        text-decoration: none;
    }

    a:hover {
        color: $gray-lighter;
    }

    .hero-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 575px;
        width: 100%;
        background: #252b33;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
        opacity: 0.5;
    }

    .hero-form, .hero-title {
        width: 100%;
        margin: 0 auto;
        display: table-cell;
        vertical-align:middle;
    }

    .hero-subtitle {
        font-size: 2rem;
        font-family: $font-family-serif;
        margin-top: 2rem;
        color: $body-bg;
    }
}

.video-hero, .no-video-hero {
    padding-top:70px;
}

/* page header */
.page-header {
    padding-bottom: 40px;
    margin: 30px 0 0px;
    border-bottom: 1px solid $gray-base;

    h1 {
        font-family: $font-family-heading;
        font-size: 60px;
        text-transform: uppercase;
    }
}

.sub-header {
    font-family: $font-family-serif;
    margin: 20px 0 20px 0;
    font-size: 20px;
}

@media (min-width: $screen-lg-min) {
    .video-hero, .no-video-hero, .blog-hero {
        height: 620px;

        .hero-overlay {
            height: 620px;
        }
    }

    .sub-header {
        font-size: 28px;
    }
}

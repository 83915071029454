.panel {

    &.panel-pricing {
        background-color: rgba(255,255,255,0.8);

        @include box-shadow(none);

        &-default {
            .close {
                color: $text-color;
            }
        }

        border: 1px solid $panel-default-border;
        border-radius: $panel-border-radius;
        color: $panel-default-text;

        .panel-heading {
            background-color: rgba(255,255,255,0.8);
            padding: $panel-heading-padding;
            border: none;

            h3 {
                font-family: $font-family-heading;
                text-transform: uppercase;
            }
        }

        .lead {
            margin-bottom: 0.5rem;
        }

        .price {
            font-size: 3rem;
        }

        .panel-body {
            background: transparent;
            padding: $panel-body-padding;
        }

        .list-group {
            background: transparent;
            min-height: 36rem;
        }
        
        .list-group-item {
            background: transparent;
            font-size: 1.5rem;
            border: none;
            padding: 0.85rem 2rem;
            margin-bottom: -0.5rem;
        }

        .panel-footer {
            background: transparent;
            padding: $panel-footer-padding;
        }
    }

    &.panel-upgrade {
        background-color: $brand-secondary;

        @include box-shadow(none);

        border: 1px solid lighten($brand-secondary, 50%);
        outline: 5px solid $brand-secondary;
        border-radius: 0;
        color: lighten($brand-secondary, 50%);
        margin-left: 5px;
        margin-right: 5px;

        .panel-heading {
            padding: 3rem 3rem 1.5rem 3rem;
            border: none;

            h3 {
                font-family: $font-family-sans-serif;
                text-transform: uppercase;
                margin: 0;
                padding: 0;
            }
        }

        .panel-body {
            background: transparent;
            padding: 1.5rem 3rem 1.5rem 3rem;

            ul {
                padding-left: 2rem;
            }
        }

        .panel-footer {
            background: transparent;
            border: none;
            padding: 1.5rem 3rem 1.5rem 3rem;

            .btn-lg {
                font-size: 15px;
            }
        }
    }
}

.modal {
    .close {
        color: $text-color;
    }
}

.tip-panel {
    color: #CCC;
    background: #FFF;
    width:100%;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.10);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.10);
    margin-bottom: 20px;
}

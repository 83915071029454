* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
          box-sizing: border-box;
}

html {
	min-height: 100%;
	position: relative;
}

body {
	background-color: $body-bg;
	margin-bottom: 80px;

	a {
	 	color: $link-color;
	  	text-decoration: none;

	  	&:hover,
	  	&:focus {
	    	color: $link-hover-color;
	    	text-decoration: $link-hover-decoration;
	    	outline: none !important;
	  	}

	  	&:focus {
	  		color: $link-color;
	  	}
	}
}

#main {
	padding-top:100px;
}

.section {
	margin-top: 4rem;
	margin-bottom: 8rem;
}


/* general layout styles */
.nopadding {
    padding: 0;
}

.nomargin {
    margin:0;
}

.padding-sm {
    margin: 0.625rem 0 !important;
}

.padding {
    margin: 2rem 0 !important;
}

.margin {
    margin:4rem;
}

.margin-sm {
    margin: 2rem;
}

.margin-lg {
    margin: 8rem;
}

.margintop {
    margin-top: 4rem;
}

.marginleft {
    margin-left: 4rem;
}

.marginright {
    margin-right: 4rem;
}

.marginleft-sm {
    margin-left: 2rem;
}

.marginright-sm {
    margin-right: 2rem;
}

.margintop-xs {
    margin-top: 1rem;
}

.marginleft-xs {
    margin-left: 1rem;
}

.margintop-sm {
    margin-top: 2rem;
}

.marginleft-sm {
    margin-left: 2rem;
}

.margintop-lg {
    margin-top: 8rem;
}

.marginbottom {
    margin-bottom: 4rem;
}

.marginbottom-xs {
    margin-bottom: 1rem;
}

.marginbottom-sm {
    margin-bottom: 2rem;
}

.marginbottom-lg {
    margin-bottom: 8rem;
}

.bordertop {
	border-top: 1px solid $gray-light;
}

.borderbottom {
	border-bottom: 1px solid $gray-light;
}

.block {
    display: block;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

[v-cloak] {
    display: none;
}
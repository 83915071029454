label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.has-feedback-left input {
    padding-left: 38px;
}

.form-control-feedback {
    left: 5px;
    color: $gray;
}

.has-feedback label {
  & ~ .form-control-feedback {
     top: ($line-height-computed + 5); // Height of the `label` and its margin
  }
  &.sr-only ~ .form-control-feedback {
     top: 10px;
  }
}

.home-form, .search-form {
	h1 {
	}

	.inline-element {
	    display: inline-block;
	}

	.inline-element-last {
	}

	.btn {
		font-family: $font-family-heading;
		color: #FFF;
	}

	.btn:focus,.btn:active {
   		outline: none !important;
	}

	.btn-search {
	    color: lighten($brand-secondary, 20%);
	    margin: 0;
	    padding: 0;
	    background-color: transparent;
	    border-radius: 0;
	    font-size: 34px;
		line-height: 34px;
		text-transform: uppercase;
	}

	.btn-primary {
		font-family: $font-family-sans-serif;
	}

	.btn-group.open .dropdown-toggle {
	    box-shadow: none;
	}

	.btn-group > .btn:first-child {
    	margin-top: -10px;
	}

	.bootstrap-select.btn-group .dropdown-toggle .filter-option {
   		width: auto;
	}

	.bootstrap-select.btn-group .dropdown-menu .selected li {
	    outline: none !important;
	    background-color: transparent;
	}

	.bootstrap-select,.bootstrap-select:hover,.bootstrap-select:focus,.bootstrap-select:active {
   		outline: none !important;
   		color: #FFF;
	}

	.bootstrap-select.btn-group .dropdown-toggle .caret {
		margin-bottom: 12px;
		vertical-align: bottom;
	}

	.dropdown-menu {
	    font-size: 30px;
		max-height:400px;
		margin:0;
	    border-radius:0;
	    background-color: $body-bg;
	}

	.dropdown-toggle {
		padding-right:0 !important;
	}

	.bs-caret {
		color: $brand-primary;

		.caret {
			border-top: 4px dashed;
			border-right: 4px solid transparent;
    		border-left: 4px solid transparent;
		}
	}
}

.home-form {
	.btn-search {
	    color: lighten($brand-secondary, 20%);
	}
}

/* radio range */
.slider-handle, .slider-tick-container {
	display: none;
}

/* photo uploads */

.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

.uploadcare-widget-button-open, .uploadcare-widget-button-open:hover, .uploadcare-widget-button-open:active, .uploadcare-widget-button-open:focus {
	background: $brand-primary;
}

/* Fixes for IE < 8 */
@media screen\9 {
  .fileinput-button input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}

.fileupload-buttonbar .btn,
.fileupload-buttonbar .toggle {
  margin-bottom: 5px;
}


@media (max-width: 767px) {
  .fileupload-buttonbar .toggle,
  .files .toggle,
  .files .btn span {
    display: none;
  }
  .files .name {
    width: 80px;
    word-wrap: break-word;
  }
  .files img,
  .files canvas {
    max-width: 100%;
  }
}

@media (min-width: $screen-lg-min) {
	.home-form, .search-form {
		.inline-element {
	    	margin: 0 1rem 0 1rem;
		}

		.inline-element-last {
	    	margin: 0 0 0 1rem;
		}

		.btn-search {
		    color: brand-secondary;
		    border-bottom: 2px solid $brand-secondary;
		    font-size: 58px;
			line-height: 55px;
		}

		.bs-caret {
			.caret {
				border-top: 8px dashed;
				border-right: 8px solid transparent;
	    		border-left: 8px solid transparent;
			}
		}
	}
}
.btn {
	font-family: $headings-font-family;
}

.btn:focus,.btn:active {
   outline: none !important;
}

.fa-btn {
    margin-right: 8px;
}

.btn-default {
	font-weight: $btn-font-weight;
	color: $gray;
	background-color: transparent;
	border-color: $gray;
}

.btn-primary {
	font-weight: $btn-font-weight;
	color: $body-bg !important;
	background-color: $brand-primary;
	border-color: $brand-primary;
	text-transform: uppercase;

	&.is-checked {
		background-color: darken($brand-primary, 10%);
		border-color: $body-bg;
	}
}

.btn-primary:link, .btn-primary:focus, .btn-primary:hover, .btn-primary:active {
	background-color: darken($brand-primary, 10%) !important;
	border-color: $body-bg;
}

.btn-lg, .btn-group-lg > .btn {
    padding: 15px 18px;
    font-size: 17px;
    line-height: 1.3333333;
    border-radius: 2px;
}

.social-links {
	.btn-social {
		font-weight: $btn-font-weight;
		text-decoration: none;
		color: #FFF;
	}

	.btn-facebook, .btn-facebook:link, .btn-facebook:visited, .btn-facebook:active {
		color: #3b5998;
		background-color: #FFF;
		border-color: #3b5998;
		text-decoration: none;
	}

	.btn-facebook:hover {
		color: #FFF;
		background-color: #3b5998;
		border-color: #3b5998;
	}

	.btn-twitter, .btn-twitter:link, .btn-twitter:visited, .btn-twitter:active {
		color: #0084b4;
		background-color: #FFF;
		border-color: #0084b4;
		text-decoration: none;
	}

	.btn-twitter:hover {
		color: #FFF;
		background-color: #0084b4;
		border-color: #0084b4;
	}
}

body {
    color: $text-color;
    font-family: $font-family-serif;
    font-size: $font-size-base;
    font-weight: 300;
}

b,
strong {
    font-family: MuseoSlab-500;
    font-weight: normal;
    font-style: normal;
}

h1 {
    font-family: $font-family-heading;
    color: $brand-secondary;
    font-size: 34px;
    width: auto;
    display: inline-block;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-weight: normal;
    text-transform: uppercase;
}

h2 {
    font-family: $font-family-sans-serif;
    font-size: $font-size-h2;
    text-transform: uppercase;
}

h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: MuseoSlab-500;
    font-weight: normal;
}

p {
    font-size: r(32px);
}


a {
    color: $link-color;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
        color: darken($link-color, 25%);
        text-decoration: none;
    }
}

.text-primary,
.text-primary:hover {
    color: $brand-primary;
}

.text-success,
.text-success:hover {
    color: $brand-success;
}

.text-danger,
.text-danger:hover {
    color: $brand-danger;
}

.text-warning,
.text-warning:hover {
    color: $brand-warning;
}

.text-info,
.text-info:hover {
    color: $brand-info;
}

.small {
    font-size: $font-size-small;
}

.faded {
    color: $gray-light;
    opacity: 0.75;

    a {
        color: $gray-light;
        font-weight: normal;
    }
}

.faded-light {
    color: $gray-lighter;
    opacity: 0.35;

    a {
        color: $gray-lighter;
        font-weight: normal;
        opacity: 0.35;
    }
}

@media (max-width: $screen-md-min) {

}

@media (min-width: $screen-lg-min) {
    h1 {
        font-size: 58px;
    }
}

/* footer */
.footer {
	bottom: 0;
	min-height: 80px;
	position: absolute;
	width: 100%;

	.footer-social-icons {
		font-size: 25px;
		padding-top: 27px;

		a {
			color: #777;
			margin-left:2rem;
			margin-right: 2rem;
		}
	}
}
.modal {

	.modal {
	}
	
	.vertical-alignment-helper {
	    display:table;
	    height: 100%;
	    width: 100%;
	}

	.vertical-align-center {
	    /* To center vertically */
	    display: table-cell;
	    vertical-align: middle;
	}

	.modal-content {
	    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
	    width:inherit;
	    height:inherit;
	    /* To center horizontally */
	    margin: 0 auto;
	}

	&.gallery-modal {
		.close {
			position:absolute;
			top:1rem;
			right: 2rem;
			font-size: 3rem;
			color: #FFF !important;
			opacity:0.8;
			text-shadow: 1px 1px 7px rgba(150, 150, 150, 1);
		}

		.modal-body {
			padding:0;
			border: 2px solid #FFF;

			img {
				width: 100%;
			}

			.modal-meta {
				position: absolute;
				bottom: 1rem;
				left:2rem;
				color: #FFF;
				opacity:0.8;
				text-shadow: 1px 1px 7px rgba(150, 150, 150, 1);
			}
		}
	}

	&.upload-modal {
		.modal-dialog {
			padding:3rem;
		}
		
		.modal-content {
			background-color: $body-bg;
		}
	}

}
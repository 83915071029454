.docs {
	background: $body-bg url('/img/topo-background.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    
	.nav {
		&.nav-pills {
			li {
				a {
					font-size: 2rem;
				}
			}

			li.active a {
				border-bottom: 0;
			}
		}
	}

	h1 {
		margin-top: 0;
		margin-bottom: 6rem;
		line-height: 0;
	}
}